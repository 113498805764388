import React, { useState, useEffect } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import CurrencyInput from 'react-currency-input';

import './styles.scss';
import OutlinedCurrencyTextField from '../OutlinedCurrencyTextField/OutlinedCurrencyTextField';

export interface BenefitValue extends WithTranslation {
  value?: number;
  setValue?: any;
}

const KeikenPayBenefitValue: React.FC<BenefitValue> = ({ t, setValue, value }) => {
  const [showMore, setshowMore] = useState<boolean>(false);
  const handleSetValue = (value: number) => {
    setValue(value);
  };
  return (
    <div className="container mt-40 BenefitValue ">
      <span className="title">{t('business.flux.keiken_pay_value_question')}</span>
      <div className="divider" />
      <OutlinedCurrencyTextField
        label={t('business.flux.keiken_pay_value')}
        value={value}
        onChange={handleSetValue}
      />
      <span
        className="subtitle"
        onClick={() => setshowMore((prevState) => !prevState)}>
        {showMore ? '-' : '+'} {t('business.flux.what_is_keiken_pay_value')}
      </span>
      {showMore && (
        <span className="message">{t('business.flux.keiken_pay_value_message')}</span>
      )}
    </div>
  );
};

export default withTranslation()(KeikenPayBenefitValue);
