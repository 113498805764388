import React from 'react';

import { withTranslation, WithTranslation } from 'react-i18next';
import BaseHeaderBenefits from 'src/Components/BaseHeaderBenefits';
import BaseFooterBenefits from 'src/Components/BaseFooterBenefits';

import './styles.scss';
import BenefitSelect from 'src/Components/BenefitSelect';
import BenefitCategories from 'src/Components/BenefitCategories';
import RenewalFrequency from 'src/Components/RenewalFrequency';
import BenefitValue from 'src/Components/BenefitValue';
import BenefitIndividualValues from 'src/Components/BenefitIndividualValues';
import RenewingPeriod from 'src/Models/Enums/RenewingPeriod';
import BusinessGroupBenefits from 'src/Services/Api/BusinessGroupBenefits';
import { useHistory, useLocation } from 'react-router-dom';
import BusinessCustomerApi from 'src/Services/Api/BusinessCustomers';
import BenefitSegments from 'src/Components/BenefitSegments/BenefitSegments';
import KeikenPayBenefitValue from 'src/Components/KeikenPayBenefitValue';

export type BusinessNewBenefit = WithTranslation;

const BusinessNewBenefit: React.FC<BusinessNewBenefit> = ({ t }) => {
  const [benefitName, setBenefitName] = React.useState<string>();
  const [benefitSegments, setBenefitSegments] = React.useState<any[]>([]);
  const [benefitValue, setBenefitValue] = React.useState<number>();
  const [keikenPayValue, setKeikenPayValue] = React.useState<number>();
  const [selectedCategories, setSelectedCategories] = React.useState<any[]>([]);
  const [period, setPeriod] = React.useState<RenewingPeriod>(
    RenewingPeriod.MONTH_PERIOD,
  );

  const [loading, setLoading] = React.useState<boolean>(false);
  const history = useHistory();

  const location = useLocation();
  const { customer } = (location.state as { customer: any }) || {};
  const createBenefit = async () => {
    if (loading == true) {
      return;
    }
    setLoading(true);
    const segmentsIds = benefitSegments.map((segment) => ({ id: segment.id }));

    const payload = {
      maxSpendingGroup: benefitValue ?? 0,
      nameGroup: benefitName,
      renewingPeriod: period,
      segments: segmentsIds,
      activityCategories: selectedCategories.map((category) => {
        if (!category.maxSpendingCategory) {
          return {
            ...category,
            maxSpendingCategory: benefitValue,
          };
        }
        return category;
      }),
    };

    const benefitid = (
      await BusinessGroupBenefits.addOneGroupBenefit({
        maxSpendingGroup: benefitValue ?? 0,
        keikenPayValue,
        nameGroup: benefitName,
        renewingPeriod: period,
        segments: segmentsIds,
        activityCategories: selectedCategories.map((category) => {
          if (!category.maxSpendingCategory) {
            return {
              ...category,
              maxSpendingCategory: benefitValue ?? 0,
            };
          }
          return category;
        }),
      })
    ).data;

    if (customer?.id) {
      await BusinessCustomerApi.editOneCustomer({
        coParticipationSegment: { id: customer?.coParticipationSegment?.id },
        groups: [
          ...customer?.groups?.map((group) => {
            return { id: group.id };
          }),
          benefitid,
        ],
        id: customer.id,
      });
    }
    history.replace('/benefits/benefits');
  };

  function handleOnChangeInvidualValue(id: number, value: number) {
    setSelectedCategories((prev) =>
      prev.map((it) => ({
        ...it,
        maxSpendingCategory:
          it.activityCategory.id == id ? value : it.maxSpendingCategory,
      })),
    );
  }

  return (
    <div className="businessNewBenefit">
      <BaseHeaderBenefits
        step="create"
        color="green"
        title={t('business.flux.new_benefit')}
        to="/benefits/benefits"
      />
      <div className="mt-100">
        <BenefitSelect
          placeholder={t('business.flux.benefit_name')}
          name="benefit"
          type="text"
          title={t('business.flux.title')}
          question={t('business.flux.subtitle')}
          value={benefitName}
          setValue={setBenefitName}
        />
        <div className="mt-30">
          <BenefitSegments
            segments={benefitSegments}
            onChange={(segments) => setBenefitSegments(segments)}
          />
        </div>
        <BenefitCategories
          selectedCategories={selectedCategories}
          setSelectedCategories={setSelectedCategories}
          maxSpendingGroup={benefitValue}
        />
        <div className="col-8 flex">
          {/* <div className="col-6 pl-0">
            <RenewalFrequency period={period} setPeriod={setPeriod} />
          </div> */}
          <div className="col-6 pr-0">
            <BenefitValue value={benefitValue} setValue={setBenefitValue} />
          </div>

            <div className="col-6 pl-0">
              <KeikenPayBenefitValue value={keikenPayValue} setValue={setKeikenPayValue} />
            </div>
          
        </div>
        <BenefitIndividualValues
          selectedActivityCategories={selectedCategories}
          onChangeValue={handleOnChangeInvidualValue}
          maxValue={benefitValue}
        />
      </div>
      <BaseFooterBenefits
        disabled={loading}
        text={t('business.flux.create_new_benefit')}
        hasBackButton="true"
        textBack="Cancelar"
        saveFunc={createBenefit}
        backTo='/benefits/benefits'
      />
    </div>
  );
};

export default withTranslation()(BusinessNewBenefit);
